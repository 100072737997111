import { Module } from 'vuex';

import type { Connection } from './../components/Schematic/iso/Connection';

/**
 * The concatenated IDs of the `to` and `from` nodes of a connection. Where the from ID always
 * comes first:
 *
 * ids = fromId + toId;
 */
export type NodeIds = string;

export type ConnectionsStoreState = {
    entriesById: { [key: NodeIds]: Connection };
    pathById: { [key: NodeIds]: Coordinates[] };
    idsByPos: { [x: number]: { [y: number]: NodeIds[] } };
    blockedById: { fromId: string; toId: string }[];
    firstCompleted: boolean;
};

export default <Module<ConnectionsStoreState, {}>>{
    namespaced: true,
    state: {
        entriesById: {},
        pathById: {},
        idsByPos: {},
        blockedById: [],
        firstCompleted: false,
    },
    getters: {
        /**
         * List of all node connections present in the store.
         */
        entries: (state: ConnectionsStoreState) =>
            Object.values(state.entriesById) as Connection[],
        /**
         * Gets the connections at a given position.
         * @param {Coordinates} c: coordinates of the position.
         */
        entriesByPos(
            state: ConnectionsStoreState
        ): (c: Coordinates) => Connection[] {
            return ({ x, y }) => {
                const ids = state.idsByPos[x]?.[y];
                if (!ids) {
                    return [];
                }
                return ids.map((id) => state.entriesById[id]);
            };
        },
        /**
         * TODO: What does this method do?
         * @param {Coordinates} c: coordinates of the position.
         */
        junctionsByPos(state) {
            return ({ x, y }: Coordinates) => {
                const ids = state.idsByPos[x]?.[y];
                if (!ids) {
                    return [];
                }
                return ids
                    .map((id) => state.entriesById[id])
                    .filter((entry) => entry.forcedPositions)
                    .filter((entry) => {
                        for (let i = 0; i < entry.forcedPositions.length; i++) {
                            if (
                                entry.forcedPositions[i].x === x &&
                                entry.forcedPositions[i].y === y
                            ) {
                                return true;
                            }
                        }
                        return false;
                    });
            };
        },
        /**
         * Gets the connection between two given nodes.
         */
        entry(
            state
        ): (nodes: { fromId: string; toId: string }) => Connection | undefined {
            return ({ fromId, toId }) => state.entriesById[fromId + toId];
        },
        /**
         * Gets the connection between two given nodes.
         */
        entryById(state): (id: Uuid) => Connection | undefined {
            return (id) =>
                Object.values(state.entriesById).filter(
                    (conn) => conn.id === id
                )[0];
        },
        /**
         * Gets a list of all the connections with a label.
         */
        entriesWithLabels(state: ConnectionsStoreState): Connection[] {
            return Object.values(state.entriesById).filter(
                (con: any) => con.labelText
            );
        },
        /**
         * TODO: What does this do? What does blocked mean in this context?
         */
        isBlocked(state): (nodes: { fromId: string; toId: string }) => boolean {
            return ({ fromId, toId }) =>
                Boolean(
                    state.blockedById.find(
                        (entry) =>
                            entry.fromId === fromId && entry.toId === toId
                    )
                );
        },
    },
    actions: {
        updateColor(
            { getters, dispatch },
            {
                fromId,
                toId,
                color,
                doNotStore,
            }: {
                fromId: string;
                toId: string;
                color: string;
                doNotStore: boolean;
            }
        ) {
            const node: Connection = getters.entry({ fromId, toId });
            node.color = color;
            node.redraw(); // Make change visible
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        updateLabelHeight(
            { getters, dispatch },
            { fromId, toId, labelHeight, doNotStore }
        ) {
            getters.entry({ fromId, toId }).labelHeight = labelHeight;
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        updateLabelText(
            { getters, dispatch },
            { fromId, toId, text, doNotStore }
        ) {
            (getters.entry({ fromId, toId }) as Connection).labelText = text;
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        updateLineType(
            { getters, dispatch },
            { fromId, toId, lineType, doNotStore }
        ) {
            const node = getters.entry({ fromId, toId });
            node.lineType = lineType;
            node.redraw(); // Make change visible
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        select({ getters }, { fromId, toId }) {
            getters.entry({ fromId, toId }).select();
        },
        deselect({ getters }, { fromId, toId }) {
            getters.entry({ fromId, toId }).deselect();
        },
        add({ state, dispatch }, { connection, doNotStore }) {
            if (!connection.fromId || !connection.toId || !connection.path) {
                throw new Error(
                    `Connection must include fromId, toId and path. fromId is now ${connection.fromId}, toId is ${connection.toId} and path ${connection.path}`
                );
            }
            state.entriesById[connection.fromId + connection.toId] = connection;
            dispatch('updatePositionMap', {
                fromId: connection.fromId,
                toId: connection.toId,
                path: connection.path,
            });
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },
        reset({ getters }, { fromId, toId }) {
            getters.entry({ fromId, toId }).resetConnection();
        },
        redrawAll({ state }, { filterNodeId = null }) {
            Object.values(state.entriesById)
                .filter(
                    ({ fromId, toId }) =>
                        !(filterNodeId === fromId || filterNodeId === toId)
                )
                .forEach((connection: any) => connection.redraw());
        },
        setBlocked({ state, getters }, { fromId, toId, blocked }) {
            if (!fromId || !toId) {
                throw new Error(
                    'FromId and ToId must be defined in order to set blocked state'
                );
            }
            if (blocked) {
                if (!getters.isBlocked({ fromId, toId })) {
                    state.blockedById.push({ fromId, toId });
                }
            } else {
                if (getters.isBlocked({ fromId, toId })) {
                    state.blockedById.splice(
                        state.blockedById.indexOf({ fromId, toId }),
                        1
                    );
                }
            }
        },
        removeAllBlocked({ state, dispatch }) {
            if (state.blockedById.length) {
                dispatch(
                    'toasts/addToast',
                    {
                        title: 'Removed all blocked connections',
                        body: 'No valid path could be found',
                        type: 'danger',
                    },
                    { root: true }
                );
                state.blockedById.forEach((entry) => {
                    dispatch('remove', {
                        fromId: entry.fromId,
                        toId: entry.toId,
                        doNotStore: true,
                    });
                });

                state.blockedById = [];
            }
        },
        // internal method
        updatePositionMap(
            { state, dispatch },
            {
                fromId,
                toId,
                path,
            }: { fromId: string; toId: string; path: { pos: Coordinates }[] }
        ) {
            if (!fromId || !toId) {
                throw new Error(
                    'from and to id must be set before map position can be updated'
                );
            }
            // Remove old
            if (state.pathById[fromId + toId]) {
                dispatch('removePathById', { fromId, toId });
            }

            // Save path
            state.pathById[fromId + toId] = path.map(({ pos }) => pos);

            path.forEach(({ pos: { x, y } }) => {
                // Begin col
                if (!state.idsByPos[x]) {
                    state.idsByPos[x] = {};
                }

                // Begin row
                if (!state.idsByPos[x][y]) {
                    state.idsByPos[x][y] = [];
                }

                // Add id to z
                state.idsByPos[x][y].push(fromId + toId);
            });
        },
        // internal method
        start({ state, dispatch }) {
            // One time popup to indicate user how to stop making a connection
            if (!state.firstCompleted) {
                dispatch(
                    'toasts/addToast',
                    {
                        title: 'Press Escape to exit',
                        body: 'To stop connecting press Escape',
                        type: 'light',
                    },
                    { root: true }
                );
                state.firstCompleted = true;
            }
        },
        // internal method
        removePathById({ state }, { fromId, toId }) {
            state.pathById[fromId + toId].forEach(({ x, y }) => {
                const index = state.idsByPos[x]?.[y]?.indexOf(fromId + toId);
                if (!(index >= 0)) {
                    throw new Error(
                        'Could not remove path. Either pathById or idsByPos is incorrect'
                    );
                }
                state.idsByPos[x][y].splice(index, 1);
                // Remove y row since it is empty
                if (!state.idsByPos[x][y].length) {
                    delete state.idsByPos[x][y];
                    // Remove x col since it is empty
                    if (!Object.keys(state.idsByPos[x]).length) {
                        delete state.idsByPos[x];
                    }
                }
            });
            delete state.pathById[fromId + toId];
        },
        // internal method
        remove({ state, dispatch }, { fromId, toId, doNotStore }) {
            // Remove css params
            document.documentElement.style.removeProperty(
                `--connection-${fromId}-${toId}-middle-x`
            );
            document.documentElement.style.removeProperty(
                `--connection-${fromId}-${toId}-middle-y`
            );

            // Remove from list
            delete state.entriesById[fromId + toId];
            dispatch('removePathById', { fromId, toId });
            if (!doNotStore) {
                dispatch('history/add', {}, { root: true });
            }
        },

        // internal method
        removeByNodeId({ state, dispatch }, { id: nodeId }) {
            Object.values(state.entriesById).forEach(({ fromId, toId }) => {
                if (!(nodeId === fromId || nodeId === toId)) {
                    return;
                }
                dispatch('remove', { fromId, toId, doNotStore: true });
            });
        },
    },
};

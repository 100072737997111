import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Ticker } from 'pixi.js';
import { GlowFilter } from 'pixi-filters';
import { v4 as uuidv4 } from 'uuid';

import { ISO_ANGLE, ISO_ROTATION } from '@/consts';
import Configuration from '@/helpers/Configuration';
import aStarSearch from '@/helpers/Connection/aStarSearch';
import { ConnectionColors, LineTypes } from '@/helpers/enums';
import $store from '@/store';

import { Label } from './Label';

class ConnectionLine extends Graphics {
    drawDashLine(
        toX: number,
        toY: number,
        dash: number = Configuration.TILE_SIZE / 4,
        gap: number = Configuration.TILE_SIZE / 4
    ) {
        // Calculate next position of the line
        const getNext = (toPos: number, pos: number, step: number) => {
            const dist = toPos - pos;

            // Do not overshoot goal
            if (dist === 0 || Math.abs(dist) < step) {
                return toPos;
            }
            const direction = dist / Math.abs(dist);
            const lineTo = pos + step * direction;
            return lineTo;
        };

        // Calc the size of the line segments
        let [x, y] = this.currentPath.points;
        const distX = Math.abs(toX - x);
        const distY = Math.abs(toY - y);
        const factor =
            Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2)) /
            (distX + distY);
        dash *= factor;
        gap *= factor;

        while (
            !(
                this.currentPath.points[0] === toX &&
                this.currentPath.points[1] === toY
            )
        ) {
            [x, y] = this.currentPath.points;
            const lineX = getNext(toX, x, dash);
            const lineY = getNext(toY, y, dash);
            this.lineTo(lineX, lineY);
            [x, y] = this.currentPath.points;
            this.moveTo(getNext(toX, lineX, gap), getNext(toY, lineY, gap));
        }
    }
}

/**
 * Graphic that draws the shortest line in the grid between one node and the mouse or another node
 */
export class Connection extends Graphics {
    /**
     * ID of this connection.
     */
    public readonly id: Uuid = uuidv4();

    /**
     * Graphic for the actual connection
     */
    private connection = new ConnectionLine();

    /**
     * Line between label and connection line.
     */
    private labelLine = new Graphics();

    /**
     * Label graphic responsible for actually rendering the label.
     */
    private label = new Label();

    /**
     * Position of start point in grid.
     */
    public startPos: Coordinates;

    /**
     * Position of end point in grid or null if the line has not been finalized.
     */
    public endPos: Coordinates | null;

    /**
     * Id of node where this line is being initiated.
     */
    public fromId: Uuid;

    /**
     * Id of the node where the line should end. Note that this might be null when the connection
     * has not been finalized.
     */
    public toId: Uuid | null;

    /**
     * Type/style of the line, i.e. solid or dotted.
     */
    public static lineType: LineTypes;

    /**
     * Previous goal of A* prevent unnecessary recalculating path
     */
    public prevGoal: Coordinates | null = null;

    /**
     * Indication of the startPosition having moved and thus path has to be recalculated
     */
    private startPointMoved: boolean = false;

    /**
     * Location of label in path; null when no label is displayed
     */
    private labelPos: Coordinates | null = null;

    /**
     * Indicate node is being dragged
     */
    public isDragged: boolean = false;

    dragPosition: Coordinates | null = null;
    forcedPositions: any[];

    paths: any[] = [];

    /**
     * Style of the line (i.e. dashed or solid).
     */
    public lineType = LineTypes.NORMAL;

    /**
     * Positions of the path.
     */
    private path: any | null = null;

    /**
     * Color of this line as hexadecimal string in format "#RRGGBB".
     *
     * Do NOT assign this directly, color updates should be assigned to the 'color' property so the
     * Pixi color is also updated correctly.
     */
    private hexColor!: string;

    /**
     * Color of the line, formatted the way Pixi expects it: 0x0RRGGBB.
     *
     * Do NOT assign this directly, color updates should be assigned to the 'color' property so the
     * hex color is also updated correctly.
     */
    private pixiColor: number = 0;

    constructor(
        startPos: Coordinates,
        fromId: string,
        color = ConnectionColors.BLUE,
        endPos = null,
        toId = null,
        forcedPositions = [],
        lineType = LineTypes.NORMAL
    ) {
        super();

        this.fromId = fromId;
        this.toId = toId;
        this.startPos = startPos;
        this.endPos = endPos;

        // Locate connection on grid
        this.setPosition();

        this.color = color;
        this.lineType = lineType;

        // forced positions of the path
        this.forcedPositions = forcedPositions;

        // positions of the path
        this.path = null;

        this.addChild(this.connection);

        this.labelLine.scale.y = Math.tan(ISO_ANGLE * 2 * (Math.PI / 180));
        this.labelLine.rotation = -ISO_ROTATION;
        this.addChild(this.labelLine);

        this.label.height = Configuration.TILE_SIZE;
        this.addChild(this.label);
        this.labelHeight = this.label.height;

        // Set correct drawing order
        this.sortableChildren = true;
        this.label.zIndex = 2;
        this.connection.zIndex = 1;
        this.labelLine.zIndex = 0;

        this.updateLabel();
    }

    public get labelText(): string {
        return this.label.text;
    }
    public set labelText(text: string | string[] | null | undefined) {
        if (text && text.length) {
            if (typeof text !== 'string') {
                text = text.join('\n');
            }
            this.label.text = text;
        } else {
            this.label.text = '';
        }
        this.label.updateGraphics();
        this.updateLabel();
    }

    public set labelHeight(y) {
        document.documentElement.style.setProperty(
            `--connection-${this.id}-label-height`,
            y + 'px'
        );
        this.label.height = y;
        this.updateLabel();
    }
    public get labelHeight() {
        return this.label.height;
    }

    get color() {
        return this.hexColor;
    }
    set color(c: ConnectionColors | string) {
        if (typeof c !== 'string' || !c.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/)) {
            throw new Error(
                `Color must be supplied as hex String and is now ${c}`
            );
        }
        this.pixiColor = parseInt(c.replace('#', ''), 16);
        this.hexColor = c;
    }

    public hasValidPath() {
        return this.path && this.path.length > 0;
    }

    // Position connection
    setPosition() {
        // Indicate the start position of the line having moved and thus path needs to be
        // recalculated.
        this.startPointMoved = true;
        this.position.set(
            this.startPos.x * Configuration.TILE_SIZE +
                Configuration.TILE_SIZE / 2,
            this.startPos.y * Configuration.TILE_SIZE +
                Configuration.TILE_SIZE / 2
        );
    }

    /**
     * Set css properties to let html elements position themselves relatively to the label
     * position of this connection
     */
    private updateCSSProperty() {
        // Due to awaiting the next tick to update css properties. Css properties can be
        // updated after connection is removed. Check if connection still exists.
        if (
            $store.getters['connections/entries'].findIndex(
                ({
                    fromId: stateFromId,
                    toId: stateToId,
                }: {
                    fromId: string;
                    toId: string;
                }): boolean =>
                    this.fromId === stateFromId && this.toId === stateToId
            ) === -1
        ) {
            return;
        }

        if (!this.toId || !this.fromId) {
            throw new Error(
                'FromId and ToId must be set before settings middle of line to css'
            );
        }

        const gPos = this.toGlobal(this.getLabelPos(this.path));
        document.documentElement.style.setProperty(
            `--connection-${this.id}-middle-x`,
            gPos.x + 'px'
        );
        document.documentElement.style.setProperty(
            `--connection-${this.id}-middle-y`,
            gPos.y + 'px'
        );
    }

    // Force connection to be redrawn
    redraw() {
        if (!this.prevGoal) {
            return;
        }
        this.draw(this.prevGoal, true);
        if (this.connection.filters && this.connection.filters.length !== 0) {
            this.select();
        }
        this.updateLabel();
    }

    static getLineFunc = (lineType: string) => {
        switch (lineType) {
            case LineTypes.DASHED:
                return 'drawDashLine';
            case LineTypes.NORMAL:
                return 'lineTo';
            default:
                throw new Error(`Linetype ${lineType} unknown`);
        }
    };

    // Calculate middle of the path where the label should be placed
    private getLabelPos(path: ConnectionPath): Coordinates {
        // Add label css
        const middlePath = path.length / 2;

        // Whole number
        if (!(middlePath % 1)) {
            const cell = path[Math.floor(middlePath) - 1];
            return {
                x: (cell.pos.x - this.startPos.x) * Configuration.TILE_SIZE,
                y: (cell.pos.y - this.startPos.y) * Configuration.TILE_SIZE,
            };
        }

        const cell = path[Math.floor(middlePath)];
        const offset = (() => {
            switch (cell.type) {
                case 'TOP':
                    return { x: 0, y: Configuration.TILE_SIZE / 2 };
                case 'TOP_LEFT':
                    return {
                        x: Configuration.TILE_SIZE / 2,
                        y: Configuration.TILE_SIZE / 2,
                    };
                case 'TOP_RIGHT':
                    return {
                        x: -Configuration.TILE_SIZE / 2,
                        y: Configuration.TILE_SIZE / 2,
                    };
                case 'RIGHT':
                    return {
                        x: -Configuration.TILE_SIZE / 2,
                        y: 0,
                    };
                case 'BOTTOM_RIGHT':
                    return {
                        x: -Configuration.TILE_SIZE / 2,
                        y: -Configuration.TILE_SIZE / 2,
                    };
                case 'BOTTOM':
                    return {
                        x: 0,
                        y: -Configuration.TILE_SIZE / 2,
                    };
                case 'BOTTOM_LEFT':
                    return {
                        x: Configuration.TILE_SIZE / 2,
                        y: -Configuration.TILE_SIZE / 2,
                    };
                case 'LEFT':
                    return { x: Configuration.TILE_SIZE / 2, y: 0 };
                default:
                    throw new Error(`Unknown type cell ${cell.type}`);
            }
        })();
        return {
            x:
                (cell.pos.x - this.startPos.x) * Configuration.TILE_SIZE +
                offset.x,
            y:
                (cell.pos.y - this.startPos.y) * Configuration.TILE_SIZE +
                offset.y,
        };
    }

    async draw({ x, y }: Coordinates, force = false) {
        // Do not redraw if not needed
        if (
            // Do not draw to same position as before exept if forced or startpoint has moved
            (!force &&
                !this.startPointMoved &&
                this.prevGoal?.x === x &&
                this.prevGoal?.y === y) ||
            // Do not draw to self
            (x === this.startPos.x && y === this.startPos.y) ||
            // Do not draw outside grid
            !(
                x >= 0 &&
                y >= 0 &&
                x < Configuration.GRID_SIZE &&
                y < Configuration.GRID_SIZE
            )
        ) {
            return;
        }
        this.startPointMoved = false;

        // Clear previous lines
        this.connection.clear();

        try {
            // Get fastest path

            // Check if connection has dragged nodes
            if (this.forcedPositions.length !== 0) {
                this.paths = [
                    await aStarSearch(
                        this.forcedPositions[this.forcedPositions.length - 1],
                        this.endPos!
                    ),
                ];

                if (this.forcedPositions.length > 1) {
                    let i;
                    for (i = this.forcedPositions.length - 1; i > 0; i--) {
                        this.paths.push(
                            await aStarSearch(
                                this.forcedPositions[i - 1],
                                this.forcedPositions[i]
                            )
                        );
                    }
                }

                this.paths.push(
                    await aStarSearch(this.startPos, this.forcedPositions[0])
                );

                this.path = this.paths.flat();
            } else {
                this.paths = [await aStarSearch(this.startPos, { x, y })];
                [this.path] = this.paths;
            }

            // Draw lines
            let prevCell: ConnectionPathEntry | null = null;
            this.connection.lineStyle(2, this.pixiColor);
            this.path.reverse().forEach((cell: any) => {
                if (!prevCell) {
                    this.connection.moveTo(0, 0);
                } else {
                    this.connection.moveTo(
                        (prevCell.pos.x - this.startPos.x) *
                            Configuration.TILE_SIZE,
                        (prevCell.pos.y - this.startPos.y) *
                            Configuration.TILE_SIZE
                    );
                }

                this.connection[Connection.getLineFunc(this.lineType)](
                    (cell.pos.x - this.startPos.x) * Configuration.TILE_SIZE,
                    (cell.pos.y - this.startPos.y) * Configuration.TILE_SIZE
                );
                prevCell = cell;
            });

            if (this.forcedPositions.length !== 0) {
                this.forcedPositions.forEach((pos: any) => {
                    this.connection.beginFill(this.pixiColor, 1);
                    this.connection.drawCircle(
                        (pos.x - this.startPos.x) * Configuration.TILE_SIZE,
                        (pos.y - this.startPos.y) * Configuration.TILE_SIZE,
                        Configuration.TILE_SIZE / 20
                    );
                });
            }

            // Draw circle on the end of the path (Visible when following mouse)
            if (!this.toId) {
                this.connection.beginFill(this.pixiColor, 1);
                this.connection.drawCircle(
                    (x - this.startPos.x) * Configuration.TILE_SIZE,
                    (y - this.startPos.y) * Configuration.TILE_SIZE,
                    Configuration.TILE_SIZE / 20
                );
            }

            // Line is connected to other node
            else {
                $store.dispatch('connections/updatePositionMap', {
                    fromId: this.fromId,
                    toId: this.toId,
                    path: this.path,
                    doNotStore: true,
                });
                // Position label to middle of path
                this.labelPos = this.getLabelPos(this.path!);

                // Update position to css after connection has been rendered and thus knows its position
                Ticker.shared.addOnce(() => {
                    this.updateCSSProperty();
                });

                $store.dispatch('connections/setBlocked', {
                    fromId: this.fromId,
                    toId: this.toId,
                    blocked: false,
                });
            }
        } catch (error) {
            this.path = [];
            if (this.toId) {
                $store.dispatch('connections/setBlocked', {
                    fromId: this.fromId,
                    toId: this.toId,
                    blocked: true,
                });
            }
            $store.dispatch('toasts/addToast', {
                title: 'Could not draw connection',
                body: 'No valid path could be found',
                type: 'warning',
            });
        }
        this.prevGoal = { x, y };

        this.updateLabel();
    }

    // Reset connection to original path
    resetConnection() {
        this.forcedPositions = [];
        $store.dispatch('history/add', {});
        this.redraw();
    }

    // Add glow around path to indicate it being selected
    select() {
        this.connection.filters = [
            new GlowFilter({
                distance: 15,
                outerStrength: 2,
                color: this.pixiColor,
            }),
        ];
    }

    // Remove glow to indicate connection not being selected anymore
    deselect() {
        this.connection.filters = [];
    }

    private updateLabel() {
        this.labelLine.clear();
        if (!this.label.text.length || !this.path) {
            return;
        }
        const pos = this.getLabelPos(this.path);

        this.label.position.x = pos.x;
        this.label.position.y = pos.y;

        this.labelLine.position.x = pos.x;
        this.labelLine.position.y = pos.y;

        this.labelLine
            .lineStyle(1, 0xcccccc)
            .moveTo(0, 0)
            .lineTo(0, -this.label.height);
    }
}

export default Connection;

import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, Sprite, Texture, Ticker } from 'pixi.js';

import { ISO_ANGLE, ISO_ROTATION } from '@/consts';
import Configuration from '@/helpers/Configuration';
import DBConnector from '@/helpers/Data/DBConnector';
import Product from '@/helpers/DataStructures/Product';
import ProductCategory from '@/helpers/DataStructures/ProductCategory';
import ProductIcon from '@/helpers/DataStructures/ProductIcon';
import ProductNode from '@/helpers/DataStructures/ProductNode';
import ProductSku from '@/helpers/DataStructures/ProductSku';
import { UI_COLORS } from '@/helpers/enums';

import { Label } from './Label';

export class Node extends Container {
    static RESOLUTION_SCALE = 5;
    static DEFAULT_OFFSET = 10;
    static defaultIcon = DBConnector.getProductIcon(
        Configuration.DEFAULT_ICON_ID
    );
    static defaultProductCategory = DBConnector.getProductCategory(0);
    static defaultProductNode = DBConnector.getProductNode(0);
    static defaultProduct = DBConnector.getProduct(0);
    static defaultProductSku = DBConnector.getProductSku(0);

    id: string;
    productSku: ProductSku | null;
    product: Product | null;
    productNode: ProductNode | null;
    productCategory: ProductCategory | null;
    userDefinedIcon: ProductIcon | null;
    userDefinedName: string | null;
    settingsPanelOpened: boolean;
    isDragged: boolean;
    selectRect: Graphics;
    image: Sprite;
    location: { x: number; y: number };
    private labelLine = new Graphics();
    private label = new Label();

    constructor(id, location) {
        super();
        this.id = id;
        this.location = location;
        this.setPosition(location.x, location.y);

        this.productCategory = Node.defaultProductCategory;
        this.productNode = Node.defaultProductNode;
        this.product = Node.defaultProduct;
        this.productSku = Node.defaultProductSku;

        // Enable the use of zIndex in this element
        this.sortableChildren = true;

        // User defined name and icon for this component
        this.userDefinedName = null;
        this.userDefinedIcon = null;

        // Store wether this node is selected due to the settings panel being open
        this.settingsPanelOpened = false;

        // Indicate node is being dragged
        this.isDragged = false;

        // Square underneath node to indicate it being selected
        this.selectRect = new Graphics();
        this.selectRect.zIndex = 1;
        this.addChildAt(this.selectRect, 0);

        // Add line displayed underneath label
        this.labelLine.zIndex = 5;
        this.labelLine.scale.y = Math.tan(ISO_ANGLE * 2 * (Math.PI / 180));
        this.labelLine.rotation = -ISO_ROTATION;
        this.addChild(this.labelLine);

        // Add image in displayed non isometrically
        this.image = new Sprite();
        this.image.zIndex = 1000;
        this.image.anchor.set(0.5, 0.5);
        this.image.rotation = -ISO_ROTATION;
        this.updateVisuals();
        this.addChild(this.image);

        this.label.height = Configuration.TILE_SIZE;
        this.label.zIndex = 2000;
        this.addChild(this.label);
        this.labelHeight = this.label.height;
    }

    setProductNode(nodeId) {
        const n = DBConnector.getProductNode(nodeId);
        if (n === null) {
            return;
        }
        const p = DBConnector.getProductsForProductNode(n.id);

        // Check if productNode has only 1 product, and select if so
        if (p.length === 1) {
            this.setProduct(p[0].id);
        } else if (n) {
            this.productSku = null;
            this.product = null;
            this.productNode = n;
            this.productCategory = n.category;
            this.updateVisuals();
        }
    }

    setProduct(productId) {
        if (typeof productId === 'undefined' || productId === null) {
            this.setProductNode(this.productNode?.id);
            return;
        }

        const p = DBConnector.getProduct(productId);
        if (p === null) {
            return;
        }
        const m = DBConnector.getProductSkusForProduct(p.id);

        // Check if product has only 1 productSku, and select if so
        if (m.length === 1) {
            this.setProductSku(m[0].id);
        } else if (p) {
            this.productSku = null;
            this.product = p;
            this.productNode = p.node;
            this.productCategory = this.productNode?.category || null;
            this.updateVisuals();
        }
    }

    setProductSku(productSkuId) {
        if (typeof productSkuId === 'undefined' || productSkuId === null) {
            this.setProduct(this.product?.id);
            return;
        }

        const m = DBConnector.getProductSku(productSkuId);

        if (m) {
            this.productSku = m;
            this.product = this.productSku.product;
            this.productNode = this.product?.node || null;
            this.productCategory = this.productNode?.category || null;
            this.updateVisuals();
        }
    }

    get icon() {
        return (
            this.userDefinedIcon || this.productNode?.icon || Node.defaultIcon
        );
    }

    get nodeName() {
        return (
            this.userDefinedName ||
            this.productSku?.name ||
            this.productNode?.name ||
            ''
        );
    }

    setuserDefinedIcon(iconId) {
        if (iconId) {
            this.userDefinedIcon = DBConnector.getProductIcon(iconId);
        }
    }

    setuserDefinedName(name) {
        if (name === '') {
            this.userDefinedName = null;
        } else {
            this.userDefinedName = name;
        }
    }

    public get labelText() {
        return this.label.text;
    }
    public set labelText(text: string | string[] | null | undefined) {
        if (text && text.length) {
            if (typeof text !== 'string') {
                text = text.join('\n');
            }
            this.label.text = text;
        } else {
            this.label.text = '';
        }
        this.label.updateGraphics();
        this.drawLabelLine();
    }

    public set labelHeight(y) {
        document.documentElement.style.setProperty(
            `--node-${this.id}-label-height`,
            y + 'px'
        );
        this.label.height = y;
        this.drawLabelLine();
    }
    public get labelHeight() {
        return this.label.height;
    }

    // Change the visual representation of the node
    updateVisuals() {
        const { icon } = this;
        if (icon === null) {
            return;
        }
        // extract scale from json
        const scale = 1;
        let offset = 0;

        // add offsets from config and json (object specific) and set relative to tile size
        offset += Node.DEFAULT_OFFSET + Configuration.ASSET_OFFSET;
        offset *= Configuration.TILE_SIZE / 100;

        const { path } = icon;

        // extract image, and set width to tile size
        //     with applied scaling factors and resolution correction
        this.image.texture = Texture.from(path, {
            resourceOptions: {
                width:
                    Configuration.TILE_SIZE *
                    Configuration.ASSET_SCALE *
                    scale *
                    Node.RESOLUTION_SCALE,
            },
        });
        // apply resolution and isometric corrections
        this.image.scale.set(
            1 / Node.RESOLUTION_SCALE,
            Math.tan(ISO_ANGLE * 2 * (Math.PI / 180)) / Node.RESOLUTION_SCALE
        );
        // set offset
        this.image.position.set(offset, offset);

        if (!this.settingsPanelOpened) {
            this.clearSelect();
        }

        this.label.updateGraphics();
    }

    setPosition(x: number, y: number) {
        this.location = { x, y };
        this.position.set(
            x * Configuration.TILE_SIZE,
            y * Configuration.TILE_SIZE
        );
        // Wait one animation frame to update global position
        //     as global position is wrong before first tick
        Ticker.shared.addOnce(() => {
            this.updateCSSProperty();
        });
    }

    getGlobalBox(includeLabels = true) {
        let { x: left, y: top } = this.toGlobal({
            x: -Configuration.TILE_SIZE,
            y: 0,
        });
        let { y: bottom } = this.toGlobal({
            x: Configuration.TILE_SIZE,
            y: Configuration.TILE_SIZE,
        });
        let { x: right } = this.toGlobal({
            x: Configuration.TILE_SIZE,
            y: 0,
        });
        const labelData = this.label.getGlobalTextPosition();
        if (includeLabels && labelData) {
            top = Math.min(top, labelData.top);
            bottom = Math.max(bottom, labelData.bottom);
            right = Math.max(right, labelData.right);
            left = Math.min(left, labelData.left);
        }
        return {
            top,
            left,
            right,
            bottom,
        };
    }

    // Set css properties to enable html elements to position themselves relative to this node
    updateCSSProperty() {
        const gPos = this.toGlobal({
            x: 0,
            y: 0,
        });
        document.documentElement.style.setProperty(
            `--node-${this.id}-middle-x`,
            gPos.x + 'px'
        );
        document.documentElement.style.setProperty(
            `--node-${this.id}-middle-y`,
            gPos.y + 'px'
        );
    }

    // Draw line to label displayed in html
    drawLabelLine() {
        this.labelLine.clear();
        if (!this.label.text.length) {
            return;
        }
        this.labelLine
            .lineStyle(1, 0xcccccc)
            .moveTo(0, Configuration.TILE_SIZE / 2)
            .lineTo(0, -this.label.height);
    }

    // Visually select node due to settings panel being opened
    settingsPanelOpen() {
        this.settingsPanelOpened = true;
        this.drawSelect();
    }

    // Visually deleted node due to settings panel being closed
    settingsPanelClose() {
        this.settingsPanelOpened = false;
        this.clearSelect();
    }

    // Visually deselect
    deselect() {
        if (this.settingsPanelOpened) {
            return;
        }
        this.clearSelect();
    }

    // Visually select
    select() {
        this.drawSelect();
    }

    hover() {
        if (this.settingsPanelOpened) {
            return;
        }
        this.drawHover();
    }

    // Draw square around node to indicate it being hovered
    drawHover() {
        this.selectRect.clear();
        this.drawRect(UI_COLORS.HOVER);
    }

    // Draw square around node to indicate it being selected
    drawSelect() {
        this.selectRect.clear();
        this.drawRect(UI_COLORS.SELECT);
    }

    clearSelect() {
        this.selectRect.clear();
        this.drawRect(UI_COLORS.DESELECT);
    }

    drawRect(color) {
        const selectColor = parseInt(color.MAIN.slice(1), 16);
        const selectAccColor = parseInt(color.ACCENT.slice(1), 16);
        this.selectRect.beginFill(selectColor, 1, true);
        this.selectRect.lineStyle(1, selectAccColor, 1);
        const padding = 0;
        this.selectRect.drawRoundedRect(
            -Configuration.TILE_SIZE * padding,
            -Configuration.TILE_SIZE * padding,
            Configuration.TILE_SIZE * (1 + 2 * padding),
            Configuration.TILE_SIZE * (1 + 2 * padding),
            7
        );
    }
}

export default Node;

import { validate } from 'jsonschema';

import Connection from '@/components/Schematic/iso/Connection';
import Node from '@/components/Schematic/iso/Node';
import $store from '@/store';

import Configuration from './Configuration';
import { ConnectionColors } from './enums';

// Layout of json
export const specV1 = {
    type: 'object',
    required: true,
    properties: {
        exportVersion: {
            type: 'number',
            required: true,
            minimum: 1,
            maximum: 1,
        },
        name: {
            type: ['String', 'null'],
        },
        notes: {
            type: ['String', 'null'],
        },
        userNotes: {
            type: 'array',
            required: false,
            items: {
                type: 'object',
                properties: {
                    id: { type: 'string', required: true },
                    text: { type: 'string', required: true },
                    title: { type: 'string', required: true },
                    location: {
                        type: 'object',
                        required: true,
                        properties: {
                            x: { type: 'number', required: true },
                            y: { type: 'number', required: true },
                        },
                    },
                },
            },
        },
        nodes: {
            type: 'array',
            required: true,
            items: {
                type: 'object',
                required: true,
                properties: {
                    id: { type: 'string', required: true },
                    productNode: { type: 'number', required: true },
                    product: { type: 'number', required: false },
                    productSku: { type: 'number', required: false },
                    userDefinedIcon: { type: 'number', required: false },
                    pos: {
                        type: 'object',
                        required: true,
                        properties: {
                            x: { type: 'number', required: true },
                            y: { type: 'number', required: true },
                        },
                    },
                    labelTexts: {
                        type: ['array', 'null'],
                        items: { type: 'string' },
                        required: false,
                    },
                    labelText: { type: 'string', required: false },
                    labelHeight: { type: ['number', 'null'] },
                    userDefinedName: {
                        type: ['string', 'null'],
                    },
                },
            },
        },
        connections: {
            type: 'array',
            required: true,
            items: {
                type: 'object',
                properties: {
                    fromId: { type: 'string', required: true },
                    toId: { type: 'string', required: true },
                    forcedPositions: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: true,
                            properties: {
                                x: { type: 'number', required: true },
                                y: { type: 'number', required: true },
                            },
                        },
                    },
                    color: {
                        type: ['string', 'null'],
                    },
                    labelTexts: {
                        type: ['array', 'null'],
                        items: { type: 'string' },
                        required: false,
                    },
                    labelText: { type: 'string', required: false },
                    labelHeight: { type: ['number', 'null'] },
                    lineType: { type: ['string', 'null'] },
                },
            },
        },
    },
};

// Import configuration from localstorage and reconstruct board based on this config

export function loadConfig(json) {
    // Empty board without adding it to history or localstorage
    $store.dispatch('nodes/reset', { doNotStore: true });
    $store.dispatch('notes/reset');
    // Update name and notes without adding it to history or localstorage
    $store.dispatch('updateProjectName', {
        projectName: json.name,
        doNotStore: true,
    });
    $store.dispatch('updateProjectNotes', {
        projectNotes: json.notes,
        doNotStore: true,
    });

    // Add nodes from json
    json.nodes.forEach(
        (
            {
                id,
                productNode,
                product,
                productSku,
                userDefinedIcon,
                pos: { x, y },
                labelTexts,
                labelText,
                labelHeight,
                userDefinedName,
            },
            index
        ) => {
            const node = new Node(id, { x, y });
            if (productSku) {
                node.setProductSku(productSku);
            } else if (product) {
                node.setProduct(product);
            } else {
                node.setProductNode(productNode);
            }
            node.userDefinedIcon = userDefinedIcon;
            node.userDefinedName = userDefinedName;

            if (labelTexts) {
                node.labelText = labelTexts;
            } else {
                node.labelText = labelText;
            }
            node.labelHeight = labelHeight;

            // Add node without adding it to history or localstorage and center viewport when last node is added
            $store.dispatch('nodes/add', {
                entry: node,
                doNotStore: true,
                centerViewportOnCluster: index === json.nodes.length - 1,
            });
        }
    );

    // Add connnections
    json.connections.forEach(
        async ({
            fromId,
            toId,
            forcedPositions,
            color,
            labelHeight,
            labelTexts,
            labelText,
            lineType,
        }) => {
            const fromNode = $store.getters['nodes/entryById'](fromId);
            const toNode = $store.getters['nodes/entryById'](toId);
            // Set default color
            if (!color) {
                color = ConnectionColors.ORANGE;
            }
            const connection = new Connection(
                {
                    x: Math.round(
                        fromNode.position.x / Configuration.TILE_SIZE
                    ),
                    y: Math.round(
                        fromNode.position.y / Configuration.TILE_SIZE
                    ),
                },
                fromId,
                color,
                {
                    x: Math.round(toNode.position.x / Configuration.TILE_SIZE),
                    y: Math.round(toNode.position.y / Configuration.TILE_SIZE),
                },
                toId,
                forcedPositions,
                lineType
            );
            if (labelTexts) {
                connection.labelText = labelTexts;
            } else {
                connection.labelText = labelText;
            }
            connection.labelHeight = labelHeight;
            await connection.draw(
                {
                    x: Math.round(toNode.position.x / Configuration.TILE_SIZE),
                    y: Math.round(toNode.position.y / Configuration.TILE_SIZE),
                },
                true
            );

            // Add Connection without adding it to history or local storage
            $store.dispatch('connections/add', {
                connection,
                doNotStore: true,
            });
        }
    );

    json.userNotes?.forEach(({ id, text, location, title }) => {
        $store.dispatch('notes/add', {
            id,
            location,
            doNotStore: true,
        });
        $store.dispatch('notes/changeTitle', {
            id,
            title,
            doNotStore: true,
        });
        $store.dispatch('notes/changeText', {
            id,
            text,
            doNotStore: true,
        });
    });
}

export function importV1(json) {
    const { valid, errors } = validate(json, specV1);
    if (!valid) {
        throw new Error(`json had errors ${errors}`);
    }

    $store.dispatch('reset');
    loadConfig(json);
}

// Export current board to localstorage for later reconstruction
export function exportV1() {
    const nodes = $store.getters['nodes/entries']().map(
        ({
            id,
            productNode,
            product,
            productSku,
            userDefinedIcon,
            position: { x, y },
            labelHeight,
            labelText,
            userDefinedName,
        }) => {
            const obj = {
                id,
                pos: {
                    x: x / Configuration.TILE_SIZE,
                    y: y / Configuration.TILE_SIZE,
                },
                labelText,
                labelHeight,
                userDefinedName,
            };
            obj.productNode = productNode.id;
            if (product) {
                obj.product = product.id;
            }
            if (productSku) {
                obj.productSku = productSku.id;
            }
            if (userDefinedIcon) {
                obj.userDefinedIcon = userDefinedIcon.id;
            }
            return obj;
        }
    );
    const connections = $store.getters['connections/entries'].map(
        ({
            fromId,
            toId,
            forcedPositions,
            color,
            labelHeight,
            labelText,
            lineType,
        }) => ({
            fromId,
            toId,
            forcedPositions,
            color,
            labelHeight,
            labelText,
            lineType,
        })
    );
    const userNotes = $store.getters['notes/entries']().map(
        ({ id, text, location, title }) => ({
            id,
            text,
            location,
            title,
        })
    );
    const json = {
        exportVersion: 1,
        name: $store.state.projectName,
        notes: $store.state.projectNotes,
        nodes,
        connections,
        userNotes,
    };

    // Trying to save empty config
    if (
        !json.name &&
        !json.notes &&
        !json.nodes.length &&
        !json.connections.length &&
        !json.userNotes
    ) {
        return null;
    }
    const { valid, errors } = validate(json, specV1);

    if (!valid) {
        throw new Error(`json had errors ${errors}`);
    }
    return json;
}

/* eslint-disable max-len */
import { createRouter, createWebHistory } from 'vue-router';

import { exportV1 } from '@/helpers/save';
import $store from '@/store';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/templates',
            name: 'Templates',
            component: () =>
                import(
                    /* webpackChunkName: "SettingsPanel" */ '@/views/Templates.vue'
                ),
        },
        {
            path: '/schematic',
            name: 'Schematic',
            component: () =>
                import(
                    /* webpackChunkName: "Schematic" */ '@/views/Schematic.vue'
                ),
            beforeEnter: (to) => {
                const localStorageConfig = localStorage.getItem('config');
                if (
                    to.path === '/schematic' &&
                    (localStorageConfig === null ||
                        localStorageConfig === 'null')
                ) {
                    console.warn('No config found, redirecting to home page');
                    return { name: 'Templates' };
                }
                return true;
            },
            children: [
                {
                    path: '/schematic/add-product',
                    name: 'AddProduct',
                    component: () =>
                        import(
                            /* webpackChunkName: "AddProduct" */ '@/components/Schematic/panels/AddProductPanel.vue'
                        ),
                },
                {
                    path: '/schematic/project-settings',
                    name: 'ProjectSettings',
                    component: () =>
                        import(
                            /* webpackChunkName: "SettingsPanel" */ '@/components/Schematic/panels/ProjectSettingsPanel.vue'
                        ),
                },
                {
                    path: '/schematic/exportimport',
                    name: 'ExportImport',
                    component: () =>
                        import(
                            /* webpackChunkName: "ExportImportPanel" */ '@/components/Schematic/panels/ExportImportPanel/ExportImportPanel.vue'
                        ),
                    beforeEnter: (to) => {
                        // if no config is available, create a blank one to suppres errors
                        // TODO: remove this when we have a better way of handling this
                        const localStorageConfig =
                            localStorage.getItem('config');
                        if (
                            localStorageConfig === null ||
                            localStorageConfig === 'null'
                        ) {
                            localStorage.setItem(
                                'config',
                                JSON.stringify(exportV1())
                            );
                        }
                    },
                },
                {
                    path: '/schematic/shop',
                    name: 'Shop',
                    component: () =>
                        import(
                            /* webpackChunkName: "BomPanel" */ '@/components/Schematic/panels/BomPanel/BomPanel.vue'
                        ),
                },
                {
                    path: '/schematic/settings/:nodeId',
                    name: 'Settings',
                    component: () =>
                        import(
                            /* webpackChunkName: "NodeSettingsPanel" */ '@/components/Schematic/panels/nodeSettingsPanel/NodeSettingsPanel.vue'
                        ),
                    beforeEnter: (to) => {
                        // reject the navigation if node is not present
                        if (
                            !$store.getters['nodes/entryById'](to.params.nodeId)
                        ) {
                            console.warn(
                                'node',
                                to.params.nodeId,
                                'does not exist'
                            );
                            return { name: 'Schematic' };
                        }
                        return true;
                    },
                },
            ],
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            component: () =>
                import(
                    /* webpackChunkName: "Schematic" */ '@/views/Templates.vue'
                ),
        },
    ],
});

export default router;

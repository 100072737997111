import { Viewport } from 'pixi-viewport';
import { Module } from 'vuex';

export type UIStoreState = {
    app: null;
    isometryPlane: null;
    tool: null;
    panel: boolean;
    popmenu: boolean;
    inputFocus: boolean;
    dragNodeId: Uuid | null;
    zoomLevel: number;
    viewport: Viewport | null;
    viewportCenter: Coordinates | null;
};

export default <Module<UIStoreState, {}>>{
    namespaced: true,
    state: {
        app: null,
        isometryPlane: null,
        tool: null,
        panel: false,
        popmenu: false,
        inputFocus: false,
        dragNodeId: null,
        zoomLevel: 1,
        viewport: null,
        viewportCenter: null,
    },
    getters: {
        state(state) {
            return state;
        },
    },
    actions: {
        registerIsoPlane({ state }, isometryPlane) {
            state.isometryPlane = isometryPlane;
        },
        registerApp({ state }, app) {
            state.app = app;
        },
        panel({ state }, comp) {
            state.panel = comp;
        },
        popmenu({ state }, comp) {
            state.popmenu = comp;
        },
        inputFocus({ state }, bool) {
            state.inputFocus = bool;
        },
        dragNodeId({ state }, id) {
            state.dragNodeId = id;
        },
        zoomLevel({ state }, level: number) {
            state.zoomLevel = level;
        },
        viewport({ state }, viewport: Viewport) {
            state.viewport = viewport;
        },
        viewportCenter({ state }, center: Coordinates) {
            state.viewportCenter = center;
        },
        tool({ state }, tool) {
            state.tool = tool;
        },
    },
};

import Configuration from '@/helpers/Configuration';
import $store from '@/store';

declare type SearchEntry = {
    pos: GridPosition;
    path: ConnectionPath;
    type: string | null;
    cost: number;
    estCostToDest: number;
};

function heuristic(startPos: GridPosition, endPos: GridPosition) {
    return Math.abs(endPos.x - startPos.x) + Math.abs(endPos.y - startPos.y);
}

export default function search(startPos: GridPosition, endPos: GridPosition) {
    return new Promise<ConnectionPath>((resolve, reject) => {
        if (startPos.x === endPos.x && startPos.y === endPos.y) {
            resolve([]);
        }
        const startCell: SearchEntry = {
            pos: startPos,
            path: [],
            type: null,
            cost: 0,
            estCostToDest: heuristic(startPos, endPos),
        };
        const cellsToBeSearched = [startCell];
        const cellsSearched: any[] = [];
        let stop = false;

        // Start search loop with heuristic search max one 5th of the total grid size
        while (
            cellsSearched.length < Math.pow(Configuration.GRID_SIZE, 2) / 5 &&
            cellsToBeSearched.length &&
            !stop
        ) {
            const mostInterestingCellIndex = cellsToBeSearched.reduce(
                (accCellIndex: any, cell, index) => {
                    if (
                        accCellIndex !== null &&
                        cellsToBeSearched[accCellIndex].cost +
                            cellsToBeSearched[accCellIndex].estCostToDest <
                            cell.cost + cell.estCostToDest
                    ) {
                        return accCellIndex;
                    }
                    return index;
                },
                null
            );
            const interestCell: SearchEntry =
                cellsToBeSearched[mostInterestingCellIndex];
            const {
                pos: { x: xInterest, y: yInterest },
                path: pathInterest,
                cost: costInterest,
            } = interestCell;
            cellsToBeSearched.splice(mostInterestingCellIndex, 1);
            const neighbours = [
                {
                    pos: { x: xInterest, y: yInterest + 1 },
                    type: 'BOTTOM',
                    length: costInterest + 1,
                }, // Top
                {
                    pos: { x: xInterest, y: yInterest - 1 },
                    type: 'TOP',
                    length: costInterest + 1,
                }, // Bottom
                {
                    pos: { x: xInterest + 1, y: yInterest },
                    type: 'RIGHT',
                    length: costInterest + 1,
                }, // Right
                {
                    pos: { x: xInterest - 1, y: yInterest },
                    type: 'LEFT',
                    length: costInterest + 1,
                }, //  Left
                {
                    pos: { x: xInterest + 1, y: yInterest + 1 },
                    type: 'BOTTOM_RIGHT',
                    length: costInterest + 1.5,
                }, //  Top right
                {
                    pos: { x: xInterest + 1, y: yInterest - 1 },
                    type: 'TOP_RIGHT',
                    length: costInterest + 1.5,
                }, //  Bottom right
                {
                    pos: { x: xInterest - 1, y: yInterest + 1 },
                    type: 'BOTTOM_LEFT',
                    length: costInterest + 1.5,
                }, //  Top left
                {
                    pos: { x: xInterest - 1, y: yInterest - 1 },
                    type: 'TOP_LEFT',
                    length: costInterest + 1.5,
                }, // Bottom left
            ];
            for (const {
                pos: { x, y },
                type,
                length,
            } of neighbours) {
                const path = [{ pos: { x, y }, type }, ...pathInterest];

                // Not within grid
                if (
                    !(
                        x >= 0 &&
                        y >= 0 &&
                        x < Configuration.GRID_SIZE &&
                        y < Configuration.GRID_SIZE
                    )
                ) {
                    continue;
                }

                // No slant through node
                $store.getters['nodes/entryByPos']({ x: x - 1, y });
                if (
                    (type === 'TOP_RIGHT' &&
                        ($store.getters['nodes/entryByPos']({ x: x - 1, y }) ||
                            $store.getters['nodes/entryByPos']({
                                x,
                                y: y + 1,
                            }))) ||
                    (type === 'TOP_LEFT' &&
                        ($store.getters['nodes/entryByPos']({ x, y: y + 1 }) ||
                            $store.getters['nodes/entryByPos']({
                                x: x + 1,
                                y,
                            }))) ||
                    (type === 'BOTTOM_RIGHT' &&
                        ($store.getters['nodes/entryByPos']({ x: x - 1, y }) ||
                            $store.getters['nodes/entryByPos']({
                                x,
                                y: y - 1,
                            }))) ||
                    (type === 'BOTTOM_LEFT' &&
                        ($store.getters['nodes/entryByPos']({ x, y: y - 1 }) ||
                            $store.getters['nodes/entryByPos']({
                                x: x + 1,
                                y,
                            })))
                ) {
                    continue;
                }

                // Goal
                if (endPos.x === x && endPos.y === y) {
                    resolve(path);
                    stop = true;
                    continue;
                }

                // Not occupied
                if ($store.getters['nodes/entryByPos']({ x, y })) {
                    continue;
                }

                // Faster path in cellsToBeSearched
                const equalCellToBeSearchedIndex = cellsToBeSearched.findIndex(
                    ({ pos: { x: xTodo, y: yTodo } }) =>
                        x === xTodo && y === yTodo
                );
                const equalCellToBeSearched =
                    cellsToBeSearched[equalCellToBeSearchedIndex];
                if (equalCellToBeSearched) {
                    if (equalCellToBeSearched.cost <= length) {
                        continue;
                    } else {
                        cellsToBeSearched.splice(equalCellToBeSearchedIndex, 1);
                    }
                }

                // Faster path in cellsSearched
                const equalCellSearchedIndex = cellsSearched.findIndex(
                    ({ pos: { x: xTodo, y: yTodo } }) =>
                        x === xTodo && y === yTodo
                );
                const equalCellSearched = cellsSearched[equalCellSearchedIndex];
                if (equalCellSearched) {
                    if (equalCellSearched.cost <= length) {
                        continue;
                    } else {
                        cellsSearched.splice(equalCellSearchedIndex, 1);
                    }
                }

                cellsToBeSearched.push({
                    pos: { x, y },
                    path,
                    cost: length,
                    type,
                    estCostToDest: heuristic({ x, y }, endPos),
                });
            }
            cellsSearched.push(interestCell);
        }
        reject(new Error('Could not find path'));
    });
}
